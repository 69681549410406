.error{
    position: relative;
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-text{
    text-align: center;
    color: #fff;
    text-shadow: 4px 4px black;
    font-size: 2.5em;
    max-width: 50%;
    font-family: 'Special Elite', cursive;
    margin-top: 2em;
}

.astronaut{
    width: 300px;
    filter: drop-shadow(0px 0px 3px black);
    animation: floating 6s ease-in-out infinite;
}

@keyframes floating {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(30px);
	}
	100% {
		transform: translatey(0px);
	}
}

// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width:480px){
    .error-text{
        font-size: 1.5em;
    }

    .astronaut{
        width: 200px;
    }
}