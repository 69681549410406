.app {
  background-image: url("./orion-nebula.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;  font-family: 'Raleway', Arial, sans-serif;
}

.starfield-animation-mobile{
  display: none;
}

// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width: 1024px){
  .starfield-animation{
    display: none;
  }
  
  .starfield-animation-mobile{
    display: unset;
  }

}





/* 
@keyframes app-ul {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
