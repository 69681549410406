.projects{
    position: relative;
    min-height: 100vh;
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
}

.project{
    width: 500px;
    min-height: 150px;
    background-color: #ffffffc4;
    margin: 1em;
    padding: 1.5em 0 ;
    border-radius: 2px;
    animation-name: margin-move;
    animation-duration: 1s;
    display: inline-block;
    overflow-x: auto;


    &-title{
        font-size: 1.5em;
        font-weight: bold;
        padding-bottom: 1em;
        max-width: 80%;
        margin: auto;
        text-align: center;
    }

    &-description{
        margin-bottom: 1em;
        max-width: 95%;
        margin: auto;
        text-align: center;
        line-height: 1.2;
    }

    &-description span{
        font-weight: bold;
    }

    &-image{
        height: 150px;
        width: 250px;
        display: block;
        margin: 1em auto;
        border-radius: 2px;
        box-shadow: 1px 1px 3px black;
    
        &:hover{
            box-shadow: 0px 0px 10px black;
        }
    }

    &-construction{
        font-weight: bold;
        font-style: italic;
        // font-size: 1.1em;
    }
}

.one{
    animation-name: margin-move-one;
}

.project a{
    color: #000;
}

.project a:hover{
    box-shadow: 0px 0px 10px black;
    text-shadow: 1px 1px 4px rgb(131, 131, 131);
}

@keyframes margin-move{
    from{
        margin-left: 0;    
    }
    50%{
        margin-left: 100px;
    }
    to{
        margin-left: 50px;
    }
}

@keyframes margin-move-one{
    from{
        margin-left: 10%;    
    }
    50%{
        margin-left: 40%;
    }
    to{
        margin-left: 30%;
    }
}


// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width:480px){
    .projects{
        padding-top: 18%;
    }

    .project{
        width: 250px;
        height: auto;
        //background-color: #20324598;
        box-shadow: 1px 1px 4px black;
        color: #fff;
        border-radius: 5px;
        animation-name: none;
        margin: 1em auto 1em ;
        padding: 1.5em ;

        &-title{
            color:#fff;
            text-shadow: 1px 1px 4px black;
        }

        &-image{
            width: 250px;
            height: 170px;
        }

        &-description{
            min-width: 100%;
            // margin: 0;
        }
    }
    
    .one{
        animation-name: margin-move;
    }
}

@media only screen and(min-width:481px) and (max-width: 768px){
    .project{
        margin: 1em auto;
        padding: 1.5em .5em;    
        width: 430px; 
    }

    .one{
        animation-name: margin-move;
    }
}

@media only screen and(min-width:1025px){
    .project{
        animation-name: appear;
        // width: 500px;
        //height: 280px;
        overflow-y: hidden;
    }
}
