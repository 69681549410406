.skills{
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hexagons{
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.hexagon{
    width: 120px;
    margin: 0 5px;
    
    &-row{
        display: flex;
    }
    
    &-text{
        font-size: 5em;
        transition-duration: 4s;
    }

    & text{
        pointer-events: none;
        text-shadow: 1px 1px 7px rgb(0, 0, 0);
        text-anchor: middle;
        font-size: 80px;
        font-weight: bold;
        fill: rgba(255, 255, 255, 0.90);
        // color: rgba(255, 255, 255, 0.90);
    }

    &-text-hidden{
        display: none;
    }
}

.hexagon-row:nth-child(1n+2) {
    margin-top: -28px;
    margin-bottom: 3px;
}

.hexagon image{
    transform: translateX(-9%);
}

// =========== RESPONSIVE MOBILE ==============

@media only screen and(min-width: 320px) and (max-width:480px) {
    .hexagon{
        width: 78px;
        margin: 0 2.5px;
    }

    .hexagon-row:nth-child(1n+2) {
        margin-top: -20px;
        margin-bottom: 3px;
    }
}

@media only screen and(min-width: 481px) and (max-width:768px){
    .skills{
        padding-top: 6%;
    }

    .hexagon{
        width: 100px;
        // margin: 0 5.5px;
    }

    .hexagon-row:nth-child(1n+2) {
        margin-top: -20px;
        // margin-bottom: 3px;
    }
}

@media only screen and(min-width:769px) and (max-width: 1024px){
    .hexagon{
        width: 110px;
        // margin: 0 2px;
    }

    .hexagon-row:nth-child(1n+2) {
        margin-top: -30px;
        // margin-bottom: 3px;
    }
}

