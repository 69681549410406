.contact{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-top: 2em;
    animation-name: appear;
    animation-duration: .6s;
    
    &-info{
        overflow-y: scroll;
        min-width: 30%;
        min-height: 20vh;
        background-color: rgba(255, 255, 255, 0.267);
        border-radius: 5px;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-text{
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
        text-shadow: 0 0 5px black;
    }

    &-email{
        font-size: 1.4em;
        margin: 1em 0;
        color: #fff;
        text-shadow: 0 0 5px black;
    }

    &-input{
        display: flex;
        flex-direction: column;
        color: #fff;
        margin: 1em;
    }   

    &-button{
        position: relative;
        padding: 1em 1.5em;
        background-color: transparent;
        border: 2px solid rgb(0, 145, 255);
        box-shadow: 0 0 5px black;
        color: rgb(0, 145, 255);
        text-transform: uppercase;
        font-size: 1em;
        transition-duration: .3s;
    }

    &-button:hover{
        box-shadow: 0px 0px 10px black;
    }
}

@keyframes appear{
    from{
        opacity: 0;    
    }
    to{
        opacity: 1;
    }
}

.input{
    padding: .5em;
    background-color: #92c7ff;
    border-radius: 3px;
}

#message{
    padding: 1em; 
    height: 6em;
}

.button-container{
    text-align: center;
}

.social{
    text-align: center;

    &-icons{
        color: #fff;
        filter: drop-shadow(0 0 5px black);
        margin: 0 .5em;
    }
}

.input-textarea{
    min-height: 170px;
}

// =========== RESPONSIVE MOBILE ==============

@media only screen and (max-width:480px){
    .contact-info{
        width: 80%;
    }

    .contact-email{
        font-size: 1.2em;
    }
}

@media only screen and(min-width:481px) and (max-width: 768px){
    .contact-text{

    }
}

@media only screen and(min-width:1025px){
    .contact-info{
        overflow-y: hidden;
    }

    .contact-email{
        margin: 2em auto 1.5em;
    }
}