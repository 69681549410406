.nav{
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    background-color: #1A2430;
    padding: .2em;
    z-index: 2;

    &-block{
        display: flex;
        flex-direction: row;
    }

    &-link, &-link-home{
        color: #fff;
        text-transform: uppercase;
        margin: .2em 1em;
        font-weight: 500;
        font-size: 1.2em;
    }

    &-list-mobile{
        background-color: #141f2be2;
        border: .2px solid rgba(255, 255, 255, 0.434);
        box-shadow: 1px 1px 3px black;
        border-radius: 3px;
        padding: .6em;
    }
    
    &-list-mobile-hidden{
        display: none;
    }

    &-link-home{
        margin-left: 1em;
    }

    &-link:after{
        content: '';
        display: block;
        width: 0;
        height: 3px;
        border-bottom: 2px solid #fff;
        transition: width .3s;
    }

    &-link:hover::after {
        width: 100%;
    }

    & .active{
        border-bottom: 2px solid #fff;
    }
}

.mobile-nav{
    display: none;
}




// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width:768px){
    .menu{
        display: none;
    }

    .mobile-nav{
        display: unset;
        position: fixed;
        right: 40px;
        top: 10px;
        text-align: right;
    }

    .nav-link-home{
        margin-left: 30px;
    }
}
