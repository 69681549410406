.home{
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-title{
        color: #fff;
        font-size: 3em;
    }

    &-name{
        color: #f0f;
        font-weight: bold;
        text-shadow: 0px 0px 10px black;
        animation-name: drop-top;
        animation-duration: 1.2s;
        position: relative;
    }
}

#first-title{
    margin-top: 80px;
}

@keyframes drop-top{
    from{
        top: -400px;    
    }
    50%{
        top: 120px;
    }
    to{
        top: 0;
    }
}
.button-text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgb(0, 145, 255);
    font-weight: bold;
    text-shadow: 0 0 5px black;
}

#arrow-button{
    margin-left: .5em;
}

#home-button{
    position: relative;
    margin-top: 2em;
    transition-duration: .3s;
    &:hover{
        box-shadow: 0px 0px 10px black;
    }
}


// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width:480px){
    .home-title{
        font-size: 2.5em;
        max-width: 90%;
    }
    
    // #first-title{
    //     margin-top: 80px;
    // }
}

@media only screen and(min-width: 481px) and (max-width:1200px){
    .home-title{
        font-size: 2.5em;
        max-width: 90%;
    }


}
