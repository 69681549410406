.career{
    position: relative;
    color: #fff;
    // overflow-y: hidden;
    padding: 0 1em;

    
    &-head{
        margin: 4em auto 1em;
    }

    &-title{
        text-transform: uppercase;
        font-weight: 800;
        font-size: 2em;
        text-align: center;
        padding: 1.5em 0;
        width: 70%;
        margin: auto;
    }
    
    &-intro{
        font-size: 1.5em;
        line-height: 1.2;
        width: 70%;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgb(222, 222, 238);

    }
    .career-intro span{
        font-weight: bold;
    }


}

.vertical-timeline-element{
    &-title{
    margin-bottom: .5em;
    font-weight: bold;
    }

    &-subtitle{
        font-style: italic;
    }
}

// =========== RESPONSIVE MOBILE ==============

@media only screen and(max-width:480px){
    .career-head{
        margin-bottom: 1em;
    }

    .career-intro{
        font-size: 1.2em;
        width: 80%;
    }    
}

@media only screen and (min-width:481px) and (max-width:1200px){
    .career-intro{
        width: 85%;
    }
    
    .vertical-timeline{
        margin: 0 2em;
        width: auto;
    }
}


